<template>
  <div class="px-5 mt-8" id="page-fortnox">
    <b-row>
      <b-col>
        <div v-if="fortnox_error" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
          <div class="alert-icon"><i class="flaticon-warning"></i></div>
          <div class="alert-text">{{ $t('FORTNOX.API_ERROR') }}</div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="2"></b-col>
      <b-col lg="8">
        <b-card title="" class="mb-2 mt-8" hide-footer>
          <div>
            <div v-if="fortnoxConnected">
              <b-form class="mt-8" @submit.prevent="saveSettings">
                <b-alert show variant="success">{{ $t('FORTNOX.IS_CONNECTED') }}</b-alert>
                <b-form-group
                  id="payment-account-group"
                  :label="$t('FORTNOX.PAYMENT_ACCOUNT')"
                  label-for="payment-account"
                  class="mt-8"
                >
                  <b-form-input id="payment-account" v-model="form.payment_account" maxlength="4">
                  </b-form-input>
                </b-form-group>
                
                <b-form-group
                  id="fee-account-group"
                  :label="$t('FORTNOX.FEE_ACCOUNT')"
                  label-for="fee-account"
                  class="mt-8"
                >
                  <b-form-input id="fee-account" v-model="form.fee_account" maxlength="4">
                  </b-form-input>
                </b-form-group>

                <b-form-group
                  id="lev-account-group"
                  :label="$t('FORTNOX.LEV_ACCOUNT')"
                  label-for="lev-account"
                  class="mt-8"
                >
                  <b-form-input id="lev-account" v-model="form.lev_account" maxlength="4">
                  </b-form-input>
                </b-form-group>

                <b-form-group
                  id="voucher-series"
                  :label="$t('FORTNOX.VOUCHER_SERIES')"
                  label-for="series"
                  class="mt-8"
                >
                  <b-form-select v-model="form.voucher_series" :options="seriesOptions"></b-form-select>
                </b-form-group>

                <b-form-group
                  id="method-group"
                  :label="$t('FORTNOX.ACCOUNTING_METHOD')"
                  label-for="radio-list"
                  class="mt-8"
                >
                <div class="radio-list">
                  <label class="radio radio-outline radio-success mb-4">
                    <input
                      type="radio"
                      v-model="form.book_method"
                      name="radios1"
                      value="KONTANT"
                    />
                    <span></span>
                    
                    {{ $t('FORTNOX.CASH_METHOD') }}
                  </label>
                  <!--<label class="radio radio-outline radio-success mb-4">
                    <input
                      type="radio"
                      v-model="form.book_method"
                      name="radios1"
                      value="FAKTURA"
                    />
                    <span></span>
                    {{ $t('FORTNOX.INVOICING_METHOD') }}
                  </label>-->
                </div>
                </b-form-group>

              <div class="alert alert-custom alert-notice alert-light-warning fade show" role="alert">
                <div class="alert-icon"><i class="flaticon-warning"></i></div>
                <div class="alert-text">{{ $t('FORTNOX.ENABLE_AUTO_BOOK_INFO') }}</div>
              </div>

                <div class="d-flex align-items-center mb-8 mt-8">
                  <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                    <input
                      type="checkbox"
                      name=""
                      v-model="form.enable_auto_book"
                    />
                    <span></span>
                  </label>
                  <span class="ml-3 cursor-pointer">{{ $t('FORTNOX.ENABLE_AUTO_BOOK') }}</span>
                </div>

              <div class="alert alert-custom alert-notice alert-light-warning fade show" role="alert">
                <div class="alert-icon"><i class="flaticon-warning"></i></div>
                <div class="alert-text">{{ $t('FORTNOX.BUNDLE_BOOKS_INFO') }}</div>
              </div>

                <div class="d-flex align-items-center mb-8 mt-8">
                  <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                    <input
                      type="checkbox"
                      name=""
                      v-model="form.bundle_books"
                    />
                    <span></span>
                  </label>
                  <span class="ml-3 cursor-pointer">{{ $t('FORTNOX.BUNDLE_BOOKS') }}</span>
                </div>

                <div class="alert alert-custom alert-notice alert-light-warning fade show" role="alert">
                  <div class="alert-icon"><i class="flaticon-warning"></i></div>
                  <div class="alert-text">{{ $t('FORTNOX.ENABLE_BGMAX_FORTNOX_INFO') }}</div>
                </div>


                
                <div class="d-flex align-items-center mb-8 mt-8">
                  <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                    <input
                      type="checkbox"
                      name=""
                      v-model="form.enable_bgmax_fortnox"
                    />
                    <span></span>
                  </label>
                  <span class="ml-3 cursor-pointer">{{ $t('FORTNOX.ENABLE_BGMAX_FORTNOX') }}</span>
                </div>


                <div class="alert alert-custom alert-notice alert-light-warning fade show" role="alert">
                  <div class="alert-icon"><i class="flaticon-warning"></i></div>
                  <div class="alert-text">{{ $t('FORTNOX.ENABLE_FORTNOX_INVOICE_INFO') }}</div>
                </div>


                
                <div class="d-flex align-items-center mb-8 mt-8">
                  <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                    <input
                      type="checkbox"
                      name=""
                      v-model="form.enable_fortnox_invoice"
                    />
                    <span></span>
                  </label>
                  <span class="ml-3 cursor-pointer">{{ $t('FORTNOX.ENABLE_FORTNOX_INVOICE') }}</span>
                </div>
                

                <div class="d-flex justify-content-between">
                  <b-button type="button" variant="outline-danger" @click.prevent="deleteFortnox"
                    >{{ $t('FORTNOX.DELETE_CONNECTION') }}</b-button
                  >
                  <b-button type="submit" variant="primary">{{ $t('COMMON.SAVE') }}</b-button>
                </div>
              </b-form>
            </div>
            
            <div v-if="!fortnoxConnected">
              <b-alert show variant="light"
                >{{ $t('FORTNOX.AUTHORIZE_INSTRUCTIONS') }}</b-alert
              >
              <b-button type="button" variant="outline-primary" @click="gotoFortnox" class="mb-8"
                >{{ $t('FORTNOX.AUTHORIZE') }}</b-button
              >
            </div>

          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss" scoped>

</style>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// TODO: Go through memlist API
const FINANCE_API_URL = 'https://finance.memlist.se/api/v1';

export default {
  name: 'fortnox-settings',
  components: {},
  props: ['company'],
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      seriesOptions: [],
      fortnox_error: false,
      financeAPIKeyIsSet: false,
      access_token: '',
      fortnox_client_id: '',
      fortnox_client_secret: '',
      fortnoxConnected: false,
      form: {
        book_method: 'KONTANT',
        lev_account: '',
        fee_account: '',
        payment_account: '',
        enable_auto_book: false,
        voucher_series: '',
        bundle_books: false,
        enable_bgmax_fortnox: false
      }
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.loadData();
      }
    }
  },
  methods: {
    validBookAccount(account) {
      const str = account + '';
      const isnum = /^\d+$/.test(str);

      return str.length === 4 && isnum;
    },
    
    gotoFortnox() {
      const state_data = {
        company_id: this.currentCompanyId,
        success_url: `${window.location.origin}/ml-settings?fortnox=success`,
        failure_url: `${window.location.origin}/ml-settings?fortnox=failure`
        // TODO: handle access token
        // access_token: this.access_token,
      };

      const url = 'https://apps.fortnox.se/oauth-v1/auth';
      const client_id = 'QBCR8J9WINPk';
      //const redirect_uri = encodeURIComponent(`${window.location.origin}/fortnox-return`);
      ///const redirect_uri = encodeURIComponent(`https://finance.memlist.se/fortnox-return`);
      // note: this return uri MUST be set to finance because fortnox only allows ONE return uri
      const redirect_uri = encodeURIComponent('https://finance.memlist.se/api/v1/oauth/fortnox-return');
      const scope = 'costcenter project bookkeeping invoice customer article companyinformation price connectfile archive';
      const access_type = 'offline';
      const response_type = 'code';
      const state = encodeURIComponent(JSON.stringify(state_data));
      location.href = `${url}?client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&access_type=${access_type}&response_type=${response_type}&state=${state}`;
    },
    async loadData() {
      if (!this.currentCompanyId) {
        return;
      }

      this.form.voucher_series = this.company.company_settings.voucher_series;
      this.form.payment_account = this.company.company_settings.payment_account;
      this.form.fee_account = this.company.company_settings.fee_account;
      this.form.lev_account = this.company.company_settings.lev_account;
      this.form.enable_auto_book = !!this.company.company_settings.enable_auto_book;
      this.form.book_method = this.company.company_settings.book_method;
      this.form.bundle_books = this.company.company_settings.bundle_books;
      this.form.enable_bgmax_fortnox = this.company.company_settings.enable_bgmax_fortnox;
      

      this.financeAPIKeyIsSet = true;
      this.getAPIKeyStatus();
    },
    getAPIKeyStatus() {
      axios 
        .get(`/fortnox/status`)
        .then(res => {
          this.fortnoxConnected = res.status === 200;
          this.fortnox_error = res.status === 500;

          if (this.fortnoxConnected) {
            this.getFortnoxVouchers();
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('FORTNOX.UNABLE_CHECK_STATUS'));
          this.fortnox_error = true;
        });
    },
    getFortnoxVouchers() {
      axios 
        .get(`/fortnox/voucherseries`)
        .then(res => {
          this.seriesOptions = [];

          for (const series of res.data.VoucherSeriesCollection) {
            this.seriesOptions.push({
              text: series.Code + ' - ' + series.Description,
              value: series.Code
            });
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('FORTNOX.UNABLE_GET_VOUCHER_SERIES'));
        });
    },
    deleteFortnox() {

      axios 
        .delete(`/fortnox/auth`)
        .then(res => {
          if (res.status === 204) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('FORTNOX.DELETED_API_CONNECTION'));
            this.fortnoxConnected = false;
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.GENERAL_ERROR'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'),  this.$t('COMMON.GENERAL_ERROR'));
        });
      
    },
    saveSettings() {
      axios
        .put(`/company/company_settings/${this.currentCompanyId}`, {
          enable_auto_book: this.form.enable_auto_book ? 1 : 0,
          voucher_series: this.form.voucher_series,
          payment_account: this.form.payment_account,
          fee_account: this.form.fee_account,
          lev_account: this.form.lev_account,
          enable_fortnox_invoice: this.form.enable_fortnox_invoice,
          book_method: this.form.book_method,
          bundle_books: this.form.bundle_books,
          enable_bgmax_fortnox: this.form.enable_bgmax_fortnox
        })
        .then(res => {
          if (res.status === 200) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
        });
    },
    getLangText(lang_text, lang) {
      for (var i = 0; i < lang_text.length; ++i) {
        if (lang_text[i].lang === lang) {
          return lang_text[i].text;
        }
      }

      return 'Språköversättning saknas';
    },
    saveData(e) {
      e.preventDefault();

      const credentials = Buffer.from(
        `${this.fortnox_client_id}:${this.fortnox_client_secret}`
      ).toString('base64');

      axios
        .get(`${FINANCE_API_URL}/oauth/token?company_id=${this.currentCompanyId}`, {
          headers: {
            Authorization: `Basic ${credentials}`
          }
        })
        .then(res => {
          if (res.status === 200) {
            axios
              .post('/company/apikey', {
                company_id: this.currentCompanyId,
                service: 'finance',
                service_id: 'finance',
                access_token: res.data.token
              })
              .then(res => {
                if (res.status === 201) {
                  this.financeAPIKeyIsSet = true;
                  this.toastr('success', this.$t('COMMON.OK'), this.$t('FORTNOX.API_SETTINGS_SAVED'));
                } else {
                  this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('FORTNOX.UNABLE_UPDATE_API'));
                }
              });
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('FORTNOX.UNABLE_UPDATE_API'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('success', this.$t('COMMON.OK'),  this.$t('FORTNOX.API_SETTINGS_SAVED'));
        });
    },

  }
};
</script>
